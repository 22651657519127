import {useAuth0} from "@auth0/auth0-react";
import {Avatar, Box, Card, CardHeader, Center, Grid, GridItem, Input, Stack, Text} from "@chakra-ui/react";
import React from "react";

export const Profile = () => {

  const { user, isAuthenticated } = useAuth0();

  const [loaded, setLoaded] = React.useState<boolean>(false)
  const [name, setName] = React.useState<string>("")
  const [email, setEmail] = React.useState<string>("")

  if (isAuthenticated) {
    if (!loaded) {
      setLoaded(true)
      setName(user!.name || "")
      setEmail(user!.email || "")
    }

    return (
      <Box w={"100%"} maxW={"100%"}>
        <Grid templateColumns='repeat(12, 1fr)' gap={6} py={"20px"}>
          <GridItem colSpan={[12,12, 6, 6]} h={"450px"} w='100%'>
            <Card h={"100%"}>
              <CardHeader>
                <Text fontSize={"2xl"}>Avatar</Text>
              </CardHeader>
              <Center h={"100%"} w={"100%"}>
                <Avatar
                  src={user!.picture}
                  width={"200px"}
                  height={"200px"}
                />
              </Center>
            </Card>
          </GridItem>
          <GridItem colSpan={[12,12, 6, 6]} w='100%' h={"450px"}>
            <Card h={"100%"}>
              <CardHeader>
                <Text fontSize={"2xl"}>Personal Information</Text>
              </CardHeader>
              <Stack p={"10px"}>
                <Text>Name:</Text>
                <Input value={name}/>
                <Text>Email:</Text>
                <Input value={email}/>
              </Stack>
            </Card>

          </GridItem>
          <GridItem colSpan={4} w='100%' h='10' />
        </Grid>

      </Box>
    )
  } else {
    return (
      <div>
        Not authenticated
      </div>
    )
  }
}

export default Profile
