import { Box, Container, Heading, SimpleGrid, Stack } from '@chakra-ui/react'
import { products } from './data'
import { PricingCard } from './PricingCard'
import React from "react";

const PricingSection = () => (
  <Box as="section">
    <Container py={{ base: '16', md: '24' }}>

      <Stack spacing={6}>
        <Heading as="h2" size="lg" textAlign={"center"} pb={"50px"}>
          Pricing
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} columnGap="8" rowGap="6" flex="1">
          {products.map((product, id) => (
            <PricingCard key={id} product={product} />
          ))}
        </SimpleGrid>
      </Stack>
      {/*<Stack spacing={{ base: '3', md: '3' }} direction={{ base: 'column', lg: 'row' }}>*/}

        {/*<Stack spacing={{ base: '1', md: '3' }} maxW="md" bg={"red"}>*/}
        {/*  <Stack spacing="3">*/}
        {/*    <Text color="accent" fontWeight="semibold">*/}
        {/*      Pricing*/}
        {/*    </Text>*/}
        {/*    <Heading size={{ base: 'sm', md: 'md' }}>Get lifetime access</Heading>*/}
        {/*  </Stack>*/}
        {/*  <Text fontSize={{ base: 'lg', md: 'xl' }} color="muted" maxW="3xl">*/}
        {/*    Choose from over 210+ beautiful and responsive components.*/}
        {/*  </Text>*/}
        {/*</Stack>*/}



      {/*</Stack>*/}
    </Container>
  </Box>
)


export default PricingSection;
