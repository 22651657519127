import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Button,
  SimpleGrid,
  Center,
  Square,
  Icon,
  Flex,
} from '@chakra-ui/react';
import {useAuth0} from "@auth0/auth0-react";
import {BsQrCode, BsTwitter, BsEmojiHeartEyes} from "react-icons/bs";
import {SiCurl} from "react-icons/si";
import {DiGoogleAnalytics} from "react-icons/di";
import {TbHandClick} from "react-icons/tb";
import PricingSection from "../Components/PricingSection";


const Feature = ({ title, text, icon }: any) => {
  return (
    <Box bg={"whiteAlpha.200"} rounded={20} boxShadow={"lg"}>
      <Flex py={"30px"} maxW={"100%"} mx={"0px"} width={"100%"} direction={"column"}>
        <Center mb={"4"}>
          <Square size='100px' bg='brand.500' color='gray.100' rounded={20}>
            <Icon as={icon} boxSize={"70px"}/>
          </Square>
        </Center>
        <Heading as="h3" size="md" mb={2} textAlign={"center"}>
          {title}
        </Heading>
        <Text align={"center"} px={"15px"}>
          {text}
        </Text>
      </Flex>
    </Box>
  )
}


export const Landing = () => {

  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/dashboard",
      },
    });
  };

  return (
    <Box>
      {/* Hero Section */}
      <Container maxW="container.lg" py={36}>
        <VStack spacing={6}>
          <Heading as="h1" textAlign={"center"} fontWeight={"extrabold"}>G2 - Go To URL Shortener</Heading>
          <Text fontSize="xl" align={"center"} pb={"20px"} maxW={"700px"}>
            Simplify your links with more compact URLs, QR Codes, and links in bio.
            Ideal for Twitter and other social media platforms.
          </Text>
          <Button fontWeight={"bold"} colorScheme="brand" size="lg" onClick={() => {handleLogin()}}>
            Get Started for Free
          </Button>
          <Text>No Credit Card Required</Text>
        </VStack>
      </Container>

      {/* Features Section */}

      <Container maxW="container.lg" py={12} >
        <VStack spacing={6}>
          <Heading as="h2" size="lg" >
            Features
          </Heading>
          <div id={"features"} />
          <SimpleGrid columns={[1, 1, 3]} spacing={10} py={12}>
            <Feature
              icon={SiCurl}
              title={"Compact URLs"}
              text={"Make your long URLs shorter and easier to share on social media and other platforms."}
            />
            <Feature
              icon={BsQrCode}
              title={"QR Codes"}
              text={"Generate QR Codes for your short URLs, making it even more convenient to share and access."}
            />
            <Feature
              icon={BsTwitter}
              title={"Links in Bio"}
              text={"Create a single link to showcase all your important content and make the most of your Twitter bio."}
            />
            <Feature
              icon={DiGoogleAnalytics}
              title={"Analytics"}
              text={"Track, analyze, and optimize your digital strategy! It's more than just a link; it's your gateway to insights."}
            />
            <Feature
              icon={BsEmojiHeartEyes}
              title={"Emojis URLs"}
              text={"Add flair to your digital footprint with emoji-laden URLs (g2.lu/🚀🌕) – a unique way to captivate clicks."}
            />
            <Feature
              icon={TbHandClick}
              title={"Engagement"}
              text={"Shortened URLs are more likely to be shared, leading to increased engagement and reach."}
            />


          </SimpleGrid>

          <div id={"pricing"} />
          <PricingSection />


        </VStack>
      </Container>
    </Box>
  )
}

export default Landing
