import { Image } from '@chakra-ui/react'
import {useNavigate} from "react-router-dom";

export const Logo = () => {
  const navigate = useNavigate();

  return (
    <Image
      onClick={() => {navigate("/")}}
      alt={"G2 Logo"}
      width={"55px"}
      height={"55px"}
      src={"G2Logo.png"}
    />)
}
