import React from 'react'
import axios from "axios";
import {Box, BoxProps, Button, ButtonGroup, Container, Divider, IconButton, Input, Stack, Text, Link, useToast} from '@chakra-ui/react'
import {FaGithub, FaLinkedin, FaTwitter} from "react-icons/fa";
import {Logo} from "../Logo";

export const Footer = (props: BoxProps) => {

  const [email, setEmail] = React.useState<string>("")

  const toast = useToast()
  const submitEmail = () => {

    const url = "/subscribe"
    const payload = {
      email: email,
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    axios.post(url, payload, { headers })
      .then((response) => {
        console.log(response)
        toast({
          title: "Success!",
          description: "You have successfully subscribed to our mailing list.",
          status: "success",
          duration: 9000,
          isClosable: true,
        })
      })
      .catch((error) => {
        console.log(error)
        toast({
          title: "Error!",
          description: "There was an error subscribing to our mailing list.",
          status: "error",
          duration: 9000,
          isClosable: true,
        })
      })
  }

  return (
    <Box as="footer" role="contentinfo" w={"100%"} maxW={"100%"} >
      <Container bg={"white"}>
      <Stack
        spacing="8"
        direction={{ base: 'column', md: 'row' }}
        justify="space-between"
        py={{ base: '12', md: '16' }}
      >
        <Stack spacing={{ base: '6', md: '8' }} align="start">
          <Logo />
          <Text>Crafted with <span style={{ color: 'red' }}> ❤ </span> in Boston</Text>
        </Stack>
        <Stack
          direction={{ base: 'column-reverse', md: 'column', lg: 'row' }}
          spacing={{ base: '12', md: '8' }}
        >
          {/*<Stack direction="row" spacing="8">*/}
          {/*  <Stack spacing="4" minW="36" flex="1">*/}
          {/*    <Text fontSize="sm" fontWeight="semibold" color="subtle">*/}
          {/*      Product*/}
          {/*    </Text>*/}
          {/*    <Stack spacing="3" shouldWrapChildren>*/}
          {/*      <Button variant="link">How it works</Button>*/}
          {/*      <Button variant="link">Pricing</Button>*/}
          {/*      <Button variant="link">Use Cases</Button>*/}
          {/*    </Stack>*/}
          {/*  </Stack>*/}
          {/*  <Stack spacing="4" minW="36" flex="1">*/}
          {/*    <Text fontSize="sm" fontWeight="semibold" color="subtle">*/}
          {/*      Legal*/}
          {/*    </Text>*/}
          {/*    <Stack spacing="3" shouldWrapChildren>*/}
          {/*      <Button variant="link">Privacy</Button>*/}
          {/*      <Button variant="link">Terms</Button>*/}
          {/*      <Button variant="link">License</Button>*/}
          {/*    </Stack>*/}
          {/*  </Stack>*/}
          {/*</Stack>*/}

          <Stack spacing="4">
            <Text fontSize="sm" fontWeight="semibold" color="subtle">
              Stay up to date
            </Text>
            <Stack spacing="4" direction={{ base: 'column', sm: 'row' }} maxW={{ lg: '360px' }}>
              <Input
                placeholder="Enter your email"
                type="email"
                onChange={(e: any) => {setEmail(e.target.value)}}
                required
              />
              <Button variant="primary" type="submit" flexShrink={0} onClick={submitEmail}>
                Subscribe
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Divider />
      <Stack
        pt="8"
        pb="12"
        justify="space-between"
        direction={{ base: 'column-reverse', md: 'row' }}
        align="center"
      >
        <Text fontSize="sm" color="subtle">
          &copy; {new Date().getFullYear()} <Link
            href={"https://www.epiphyte.io"}
            textDecoration="underline"
            _hover={{ cursor: "pointer" }}
          >
           EPIPHYTE LLC
          </Link>. All rights reserved.
        </Text>
        <ButtonGroup variant="ghost">
          <IconButton
            as="a"
            href="https://www.linkedin.com/company/epiphytellc"
            aria-label="LinkedIn"
            icon={<FaLinkedin fontSize="1.25rem" />}
          />
          <IconButton as="a" href="https://github.com/epiphyte" aria-label="GitHub" icon={<FaGithub fontSize="1.25rem" />} />
          <IconButton as="a" href="https://twitter.com/g2shorturl" aria-label="Twitter" icon={<FaTwitter fontSize="1.25rem" />} />
        </ButtonGroup>
      </Stack>
      </Container>
    </Box>
  )
}
