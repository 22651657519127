import * as React from "react"
import {
  ChakraProvider, extendTheme,
} from "@chakra-ui/react"
import { theme as proTheme } from '@chakra-ui/pro-theme'
import {Layout} from "./Components/Layout";

import "@fontsource/inter"
import {Routes, Route} from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import Landing from "./Pages/Landing";
import Profile from "./Pages/Profile";


export const g2Theme = extendTheme(
  {
    fonts: {
      heading: "Inter, sans-serif",
      body: "Inter, sans-serif",
    },
    colors: {
      // "gray": "#8F8AC0",
      // "dark": "#171435",
      // ...proTheme.colors,
      brand: {
        "gray": "#8F8AC0",
        "darkblue": "#171435",
        "dark": "#171435",
        "blue": "#232f6b",
        "muted": "#b2243b",
        "light": "#d6d4e8",
        // "50": "#fd29df",
        // "100": "#fd0fdb",
        // "200": "#f102cf",
        // "300": "#d802b9",
        // "400": "#bf01a3",
        "500": "#f62f63",
        // "600": "#730162",
        // "700": "#59014d",
        // "800": "#400037",
        // "900": "#270021"
      }
    },
    components: {
      Text: {
        baseStyle: {
          // fontFamily: "Your Font Name, sans-serif",
          color: "brand.blue",
          // color: "white"
        },
      },
      Heading: {
        baseStyle: {
          // fontFamily: "Your Font Name, sans-serif",
          color: "brand.blue",
          // color: "white"
        },
      },
      Button: {
        baseStyle: {
          color: "brand.500",
        }
      }
    },
  },
  proTheme,
)


export const App = () => {


  return (
  <ChakraProvider theme={g2Theme}>
    <Layout>

      <Routes>
        <Route path="/" element={<Landing/>}/>
        <Route path="/profile" element={<Profile/>}/>
        <Route path="/dashboard" element={<Dashboard/>}/>
        <Route path="*" element={<Dashboard/>}/>
      </Routes>

    </Layout>
  </ChakraProvider>
  )
}
