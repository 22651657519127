import {Avatar, Box, IconButton, Menu, MenuButton, MenuItemOption, MenuList} from "@chakra-ui/react";
import * as React from "react";
import {useNavigate, Link} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {useEffect} from "react";
import { FiMenu } from 'react-icons/fi'


export const AvatarMenu = () => {
  const navigate = useNavigate();

  const {
    isLoading,
    user,
    logout,
    loginWithRedirect,
    isAuthenticated
  } = useAuth0();

  const [name, setName] = React.useState<string>("")
  const [picture, setPicture] = React.useState<string>("")

  useEffect(() => {
    if(isAuthenticated) {
      console.log("AvatarMenu")
      if (!isLoading) {
        setName(user!.name || "")
        setPicture(user!.picture || "")
      }
    }
  }, [isLoading, isAuthenticated])

  const signOut = async () => {
    await logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
  }

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/dashboard",
      },
    });
  };

  if (isAuthenticated) {
    return (
      <Box>
        <Menu>
          <MenuList minWidth='240px'>
            <MenuItemOption
              onClick={() => {
                navigate("/")
              }} value='asc'>
              Home
            </MenuItemOption>
            <MenuItemOption
              onClick={() => {
                navigate("/profile")
              }} value='asc'>
              Profile
            </MenuItemOption>
            <MenuItemOption
              onClick={() => {
                navigate("/dashboard")
              }} value='asc'>
              My URLs
            </MenuItemOption>
            <MenuItemOption
              onClick={signOut}
              value='asc'>
              Logout
            </MenuItemOption>
          </MenuList>
          {user && <MenuButton
            as={Avatar}
            colorScheme='brand'
            name={name}
            src={picture}
            aria-label={"Avatar menu"}
          >
          </MenuButton>}
        </Menu>
      </Box>
    )
  }


  return (
      <Menu>
        <MenuButton
          as={IconButton}
          variant="ghost"
          icon={<FiMenu fontSize="1.25rem" />}
          aria-label="Open Menu"
        />
        <MenuList minWidth='240px'>
          <MenuItemOption
            onClick={handleLogin}
            value='asc'>Sign In
          </MenuItemOption>
          <MenuItemOption
            onClick={handleLogin}
            value='asc'>Sign Up
          </MenuItemOption>
          <MenuItemOption
            as={Link}
            to={"/#features"}
          >
            Features
          </MenuItemOption>
          <MenuItemOption
            as={Link}
            to={"/#pricing"}
          >
            Pricing
          </MenuItemOption>
        </MenuList>
      </Menu>
  )
}
