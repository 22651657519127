import axios from "axios";


const API_URL = process.env.REACT_APP_G2_API_URL || "https://www.g2.lu";

export function newURL(email: string, destinationURL: string) {
  return axios.post(API_URL + "/api/add_new_url", {
    email: email,
    destinationURL: destinationURL,
  })
}


export function getURLs(email: string) {
  return axios.post(API_URL + "/api/get_url_list",{
    email: email
  })
}


export function deleteURL(email: string, urlCode: string) {
  return axios.post(API_URL + "/api/delete_url", {
    email: email,
    urlCode: urlCode,
  })
}
