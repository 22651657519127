import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel, useToast,
} from '@chakra-ui/react';
import {useAuth0} from "@auth0/auth0-react";
import {newURL} from "../SDK/SDK";


type NewURLModalProps = {
  refresh: () => void
}

const NewURLModal = ({refresh}: NewURLModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [destinationURL, setDestinationURL] = useState('');

  const toast = useToast()
  const {user} = useAuth0();

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handleCreate = () => {
    const email: string = user?.email || ""

    if (email !== "") {
      // Create the URL
      newURL(email, destinationURL)
        .then((res) => {
        toast({
          position: "top-right",
          title: "Success!",
          description: "You have successfully created a new G2 URL!",
          status: "success",
          duration: 5000,
          isClosable: true,
        })
        refresh();
        handleClose();
      }).catch((err) => {
        console.log(err);
        toast({
          position: "top-right",
          title: "Error!",
          description: err,
          status: "error",
          duration: 9000,
          isClosable: true,
        })
      });
    } else {
      toast({
        title: "Error!",
        position: "top-right",
        description: "You are not logged in!",
        status: "error",
        duration: 9000,
        isClosable: true,
      })
    }

    // Close the modal after creating

  };

  return (
    <>
      <Button colorScheme="brand" onClick={handleOpen} width={["100%", "100%", "100%", "300px"]} py={"20px"}>
        New URL
      </Button>

      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New URL</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Destination URL</FormLabel>
              <Input
                type="url"
                value={destinationURL}
                onChange={(e) => setDestinationURL(e.target.value)}
                placeholder="Enter destination URL"
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme={"blue"} mr={3} onClick={handleCreate}>
              Create
            </Button>
            <Button colorScheme={"brand"} onClick={handleClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default NewURLModal;
