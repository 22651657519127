import React, {useRef, useEffect} from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Text,
  Link,
  Flex,
  Stack,
  useToast,
  Grid, GridItem, Card, useBreakpointValue, Center
} from "@chakra-ui/react";
import NewURLModal from "../Components/NewURLModal";
import QRCodeStyling from "qr-code-styling";
import {useAuth0} from "@auth0/auth0-react";
import {getURLs} from "../SDK/SDK";
import DeleteConfirmationModal from "../Components/DeleteConfiramtionModal";

const QRCode = ({ URL }: { URL: string }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const qrCode = new QRCodeStyling({
      width: 175,
      height: 175,
      image: "/G2Logo.png",
      dotsOptions: {
        color: "#171435",
        // type: "rounded"
      },
    });


    // @ts-ignore
    qrCode.append(ref.current);

    qrCode.update({
      data: URL
    });

    return () => {
      // qrCode.clear();
    };
  }, [URL]);

  return (
    <Box h="175px">
      <div ref={ref} />
    </Box>
  );
};





export const Dashboard = () => {
  const [loaded, setLoaded] = React.useState<boolean>(false)
  const [data, setData] = React.useState<any>([])
  const { user } = useAuth0();
  const toast = useToast()


  const isDesktop = useBreakpointValue({ base: false, lg: true })

    if (!loaded) {
      const email: string = user?.email || ""

      if (email !== "") {
        getURLs(email)
          .then((res) => {
            console.log(res)
            setData(res.data)
            setLoaded(true)
          }).catch((err) => {
          console.log("=== Error ===")
          setData([])
          setLoaded(true)
          console.log(err);
          toast({
            title: "Error!",
            description: err,
            status: "error",
            duration: 9000,
            isClosable: true,
          })
        });
      }
    }




    const handleEdit = (id: string) => {
      console.log('Edit:', id);
    };

    const refresh = () => {
      setLoaded(false)
    }

    const Item = ({children, colSpan}: any) => {
      return (
        <GridItem colSpan={colSpan} bg={"white"} p={"20px"}>
          <Flex alignItems="center" h="100%">
            {/*<Box>*/}
            {children}
            {/*</Box>*/}
          </Flex>
        </GridItem>
      )
    }

    const ItemTitle = ({children, colSpan}: any) => {
      return (
        <GridItem colSpan={colSpan} bg={"gray.50"} p={"20px"}>
          <Flex alignItems="center" h="100%">
            <Box>
              <Text fontSize={16} color={"#4A5568"}>{children}</Text>
            </Box>
          </Flex>
        </GridItem>
      )
    }

      return (
        <Box overflowX="auto" py={"50px"}>
          <Stack py={"20px"}>
            <Text fontSize={32} fontWeight={"bold"}>My URLS</Text>

            { isDesktop ? (
            <Grid pt={"50px"} width={"100%"} templateColumns='repeat(24, 1fr)' >
              <ItemTitle colSpan={4}>Short URL</ItemTitle>
              <ItemTitle colSpan={[5, 5, 5, 6, 7]}>Destination URL</ItemTitle>
              <ItemTitle colSpan={5}>Date</ItemTitle>
              <ItemTitle colSpan={[6, 6, 6, 5, 4]}>QR Code</ItemTitle>
              <ItemTitle colSpan={4}>Control</ItemTitle>
              {data.map((item: any, index: any) => {
                 return (
                   <>
                     <Item colSpan={4} bg={"white"}>
                       <Link href={"https://g2.lu/" + item.shortURL}>
                         {"https://g2.lu/" + item.shortURL}
                       </Link>
                     </Item>
                     <Item colSpan={[5, 5, 5, 6, 7]} bg={"white"}>
                       <Link style={{ wordBreak: "break-all" }} href={item.destinationURL} isExternal>
                         {item.destinationURL}
                       </Link>
                     </Item>
                     <Item colSpan={5} bg={"white"}>{item.createdAt}</Item>
                     <GridItem colSpan={[6, 6, 6, 5, 4]} bg={"white"} p={"20px"} >

                       <QRCode URL={"https://g2.lu/" + item.shortURL}/>

                     </GridItem>
                     <Item colSpan={4} bg={"white"}>
                       <ButtonGroup maxW={"100%"} >
                         <Button color="brand.dark" onClick={() => handleEdit(item.id)}>
                           Edit
                         </Button>
                         <DeleteConfirmationModal item={item} refresh={refresh}/>
                       </ButtonGroup>
                     </Item>
                     <GridItem colSpan={24} h={"3px"} bg={"brand"}/>
                   </>
                 )
                } )}
            </Grid> ) : (
            <Stack spacing={"10px"} p={"10px"} bg={"gray.100"} rounded={20}>
              {data.map((item: any, index: any) => {
                return (
                  <Card boxShadow={"lg"} p={"10px"} rounded={20} pt={"20px"}>
                    <Center>
                    <Stack alignItems={"center"} spacing={"15px"}>
                      <Link href={"https://g2.lu/" + item.shortURL} color={"brand.500"} fontSize={24}>
                        {"https://g2.lu/" + item.shortURL}
                      </Link>
                      <Link style={{ wordBreak: "break-all" }} href={item.destinationURL} isExternal>
                        {item.destinationURL}
                      </Link>

                      <QRCode URL={"https://g2.lu/" + item.shortURL}/>
                      <ButtonGroup py={"20px"}>
                        <Button color="brand.dark" onClick={() => handleEdit(item.id)}>
                          Edit
                        </Button>
                        <DeleteConfirmationModal item={item} refresh={refresh}/>
                      </ButtonGroup>
                    </Stack>
                    </Center>
                  </Card>
                )
              })}
            </Stack>
            )
            }


          </Stack>
          <NewURLModal refresh={refresh}/>
        </Box>
      )

}

export default Dashboard
