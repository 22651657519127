import {
  Table,
  Tbody,
  Tr,
  Td,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure, useToast,
} from "@chakra-ui/react";
import {useAuth0} from "@auth0/auth0-react";
import {deleteURL} from "../SDK/SDK";

function DeleteConfirmationModal({ item, refresh } : any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast()
  const {user} = useAuth0();

  const handleDelete = () => {
    const email: string = user?.email || ""
    if (email !== "") {
      deleteURL(email, item.shortURL)
        .then((res) => {
          toast({
            title: "Success!",
            description: "You have successfully deleted a G2 URL!",
            status: "success",
            duration: 5000,
            isClosable: true,
          })
          refresh();
          onClose();
        }).catch((err) => {
        console.log(err);
        toast({
          title: "Error!",
          description: err,
          status: "error",
          duration: 9000,
          isClosable: true,
        })
      });
    } else {
      toast({
        title: "Error!",
        description: "You must be logged in to delete an URL!",
        status: "error",
        duration: 9000,
        isClosable: true,
      })
    }

    onClose();
  };

  return (
    <>
      <Button colorScheme="brand" onClick={onOpen}>
        Delete
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Delete</ModalHeader>
          <ModalBody>
            Are you sure you want to delete this URL? This action cannot be undone.

            <Table variant="simple">
              <Tbody>
                <Tr>
                  <Td><Text fontWeight={"bold"}>Short URL:</Text></Td>
                  <Td>https://g2.lu/{item.shortURL}</Td>
                </Tr>
                <Tr>
                  <Td><Text fontWeight={"bold"}>Destination:</Text></Td>
                  <Td>{item.destinationURL}</Td>
                </Tr>
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="brand" onClick={handleDelete}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default DeleteConfirmationModal;
