
export const products = [
  {
    name: 'Personal',
    price: 'Free',
    description: 'For Personal Projects',
    features: ['20 URLs', 'QR Codes', 'Emoji URLs', 'Basic Analytics'],
    isPopular: true,
  },
  {
    name: 'Premium',
    price: '$5',
    description: 'For Advanced Users',
    features: ['200 URLs', 'Personalized QR Codes', 'Custom URLs', 'Advanced Analytics'],
    isComingSoon: true,
  },
]

export type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<
    infer ElementType
  >
  ? ElementType
  : never

export type Product = ElementType<typeof products>


