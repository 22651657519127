import {Box, Button, ButtonGroup, Container, Flex, HStack, useBreakpointValue} from '@chakra-ui/react'
import {Logo} from "../Logo";
import {useAuth0} from "@auth0/auth0-react";
import {AvatarMenu} from "./AvatarMenu";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";


type NavbarProps = {
  handleLogin: () => void
}
const UnauthenticatedNavbar = ({handleLogin}: NavbarProps) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true })


  const handleOnClick = (anchor: string) => {
    window.location.href = anchor;
  }

  return (
    <>
    {isDesktop ? (
        <Flex justify="space-between" flex="1">
          <ButtonGroup variant={"link"} spacing={8}>
            <Button onClick={() => handleOnClick("/#features")} >Features</Button>
            <Button onClick={() => handleOnClick("/#pricing")} >Pricing</Button>
          </ButtonGroup>
          <HStack spacing="3">
            <Button variant="ghost" onClick={handleLogin}>Sign in</Button>
            <Button variant="primary" onClick={handleLogin}>Sign up</Button>
          </HStack>
        </Flex>
      ) : (
        <AvatarMenu/>
        // <IconButton
        //   variant="ghost"
        //   icon={<FiMenu fontSize="1.25rem" />}
        //   aria-label="Open Menu"
        // />
      )}
    </>
  )
}


const AuthenticatedNavbar = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true })
  const navigate = useNavigate();

  return (
    <>
      {isDesktop &&
        <Flex justify="space-between" flex="1">
          <ButtonGroup variant="link" spacing="8">
            <Button onClick={() => navigate("/")}>Home</Button>
            <Button onClick={() => navigate("/dashboard")}>My URLs</Button>
            <Button onClick={() => navigate("/profile")}> Profile</Button>
          </ButtonGroup>
        </Flex>
      }
      <AvatarMenu/>
    </>
  )
}


export const Navbar = () => {
  const [loaded, setLoaded] = React.useState<boolean>(false)
  const {isLoading, isAuthenticated, loginWithRedirect } = useAuth0();


  useEffect(() => {
      if (!loaded) {
        setLoaded(true)
      }
  }, [isLoading])

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/profile",
      },
    });
  };


  console.log(isAuthenticated)

  if(isLoading) {
    return (<div>Loading...</div>)
  } else {
    return (
      <Box as="nav" role="navigation">
        <Box as="section" pb={{ base: '0', md: '0' }}>
          <Box as="nav" bg="bg-surface" boxShadow={'sm'}>
      {/*Box as="nav" role="navigation" w={"100%"} maxW={"100%"}>*/}
        <Container py={{base: '4', lg: '5'}}>
          <HStack spacing="10" justify="space-between">
            <Logo />
            {
              isAuthenticated ? (
                <AuthenticatedNavbar />
              ) : (
                <UnauthenticatedNavbar handleLogin={handleLogin}/>
              )
            }
          </HStack>
        </Container>
      </Box>
        </Box>
      </Box>
    )
  }
}
