import {BoxProps, Container, Flex} from '@chakra-ui/react'
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import React from "react";

export const Layout = (props: BoxProps) => {

  console.log("Layout")

  return (
    <Flex
      direction="column"
      flex="1"
      minHeight={"100vh"}
      background={`url('g2pattern.svg') rgba(0, 0, 0, 0.9)`}
      backgroundSize="cover"
      backgroundPosition="center"
    >
      <div style={{ backgroundColor: "rgba(255, 255, 255, 0.5)", width: "100%", height: "100%" }}>
        <Navbar/>
          <Flex as="main" role="main" direction="column" flex="1" py="15"  {...props}>
            <Container flex="1" bg={"white"} pt={"50px"}>
              {props.children}
            </Container>
          </Flex>
        <Footer/>
      </div>
     </Flex>
  )
}
